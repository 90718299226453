import React, {useEffect, useState} from "react";
import {BACKEND_URL, doPost} from "../lib/routing";
import {SlideshowWithOverlay} from "../components/custom/slideshow-with-overlay";
import {SLIDE_SHOW_IMAGES} from "../common/slide-show-images";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import {useToast} from "../components/ui/use-toast";
import {ToastAction} from "../components/ui/toast";
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "../components/ui/form";
import {Input} from "../components/ui/input";
import {AtSign} from "lucide-react";
import {Textarea} from "../components/ui/text-area";
import {Button} from "../components/ui/button";
import {IconReload} from "@tabler/icons-react";

export const SayHello = () => {

    useEffect(() => {
        document.title = "Aseem Savio | Contact"

        fetch(`${BACKEND_URL}/api/v1/page-visit`, {
            method: 'POST', // Set the HTTP method to POST
            headers: {
                'Content-Type': 'application/json', // Tell the server you're sending JSON
            }
        }).then(resp => console.log(resp))
    }, []);

    const {toast} = useToast()
    const [loading, setLoading] = useState(false)

    const formSchema = z.object({
        name: z.string().min(3, {
            message: 'Name is too short.',
        }),
        email: z.string().email({
            message: 'Invalid email address',
        }),
        message: z.string().min(5, {
            message: 'Message is too short. Your message should be over 5 characters long.',
        }).max(200, {
            message: 'Message is too long. Your message should be under 200 characters.',
        })
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            email: "",
            message: "",
        }
    })

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        const payload = {
            name: values.name,
            email: values.email,
            message: values.message,
        }

        setLoading(true)
        const response = await doPost("/api/v1/user-message", payload)
        if (response.ok) {
            setLoading(false)
            toast({
                title: "Message Sent Successfully",
                description: "Your message has reached me. Await an email from me.",
                action: (
                    <ToastAction altText="Ok">Ok</ToastAction>
                ),
            })
        } else {
            setLoading(false)
            console.error(response)
            toast({
                variant: 'destructive',
                title: "Uh oh! Something went wrong.",
                description: "There was a problem sending your message.",
                action: (
                    <ToastAction altText="Try Again">Ok</ToastAction>
                ),
            })
        }
    }

    return (
        <div>
            <div className="flex flex-col gap-2 flex-1 w-full h-full overflow-y-scroll">
                <SlideshowWithOverlay images={SLIDE_SHOW_IMAGES}>
                    <div
                        className="absolute inset-0 flex flex-col p-8 bg-opacity-50 bg-black overflow-y-scroll overflow-x-hidden">
                        <p className={"md:text-7xl text-4xl font-extrabold text-white"}>DROP ME A</p>
                        <p className={"md:text-7xl text-2xl font-extrabold text-yellow-200"}>MESSAGE</p>
                        <br/>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)}>
                                <div className={"w-screen flex flex-col items-center"}>
                                    <div className={"bg-black bg-opacity-20 md:w-2/3 w-4/5 md:p-20 p-5"}>
                                        <FormField
                                            control={form.control}
                                            name={"name"}
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className={"text-white"}>Name</FormLabel>
                                                    <FormControl>
                                                        <Input className={"bg-transparent text-white"}
                                                               placeholder={"Jim"} {...field}/>
                                                    </FormControl>
                                                    <FormDescription className={"text-white"}>
                                                        Enter your name here.
                                                    </FormDescription>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <br/>
                                        <FormField
                                            control={form.control}
                                            name={"email"}
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className={"text-white"}>Email</FormLabel>
                                                    <FormControl>
                                                        <div className={"relative"}>
                                                            <Input className={"bg-transparent text-white peer ps-9"}
                                                                   placeholder="jim@example.com"
                                                                   type="email" {...field} />
                                                            <div
                                                                className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 text-muted-foreground/80 peer-disabled:opacity-50">
                                                                <AtSign size={16} strokeWidth={2} aria-hidden="true"
                                                                        className={"text-white"}/>
                                                            </div>
                                                        </div>
                                                    </FormControl>
                                                    <FormDescription className={"text-white"}>
                                                        Enter your email address here. Don't worry I won't dox you xD
                                                    </FormDescription>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <br/>
                                        <FormField
                                            control={form.control}
                                            name={"message"}
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className={"text-white"}>Your Message</FormLabel>
                                                    <FormControl>
                                                        <Textarea className={"bg-transparent text-white"}
                                                                  placeholder="Hello, Aseem. Wassup?" {...field}/>
                                                    </FormControl>
                                                    <FormDescription className={"text-white"}>
                                                        Leave a message for me here.
                                                    </FormDescription>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <br/>
                                        <Button className={'w-[150px] bg-yellow-200'} disabled={loading} type={"submit"}>
                                            {loading && (
                                                <IconReload className="mr-2 h-4 w-4 animate-spin"/>
                                            )}
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </Form>
                    </div>
                </SlideshowWithOverlay>
            </div>
        </div>
    )
}
