import {Timeline} from "../components/ui/timeline";
import React, {useEffect} from "react";
import {BACKEND_URL} from "../lib/routing";
import {SlideshowWithOverlay} from "../components/custom/slideshow-with-overlay";
import {SLIDE_SHOW_IMAGES} from "../common/slide-show-images";

export const ProfessionalTimeline = () => {

    useEffect(() => {
        document.title = "Aseem Savio | Professional Timeline"

        fetch(`${BACKEND_URL}/api/v1/page-visit`, {
            method: 'POST', // Set the HTTP method to POST
            headers: {
                'Content-Type': 'application/json', // Tell the server you're sending JSON
            }
        }).then(resp => console.log(resp))
    }, []);

    const data = [
        {
            title: "2022 - Now",
            content: (
                <div className={"text-teal-50"}>
                    <p className={"text-3xl font-extrabold"}>Tenable</p>
                    <br/>
                    <p><strong>Senior Software Engineer</strong>: I design and develop features for a distributed
                        exports platform using <b>Kotlin, Java, Groovy</b>, and build microservices for bulk exports
                        of assets, vulnerabilities, and compliance data. My expertise includes building distributed,
                        asynchronous APIs and working with sharded data stores
                        like <b>ElasticSearch</b> and <b>CockroachDB</b> in a multi-tenant Kubernetes environment. I
                        also contribute to building UI components using <b>React</b>.</p>
                    <br/>
                    <p><strong>Key Contributions</strong>: I spearhead the Hydration Algorithm to export millions of
                        customer assets with open port information. I co-design a distributed data ingestion pipeline
                        for Atlassian, leveraging <b>Kotlin Coroutines, Kafka,</b> and Kubernetes, handling hundreds
                        of thousands of events per hour. I also improve REST API throughput with <b>Kotlin
                            DSLs</b> and enhance DynamoDB interactions, resulting in an
                        open-source <b>dynamo-kt</b> library.</p>
                    <br/>
                    <p><strong>Additional Work</strong>: I build PDF export functionality for executive summaries
                        using <b>Kotlin, Apache Parquet, DuckDB, AWS S3,</b> and <b>DynamoDB</b>. As a maintainer of
                        the open-source <b>pyTenable</b> library, I support Tenable APIs and co-develop <b>iris</b>,
                        a CLI for Tenable APIs. I provide leadership for the Exports & Integrations team and guide
                        ServiceNow Engineers on API usage and integrations.</p>
                </div>
            )
        },
        {
            title: "2021 - '22",
            content: (
                <div className={"text-teal-50"}>
                    <p className={"text-3xl font-extrabold"}>Publicis Sapient</p>
                    <br/>
                    <p><strong>E-commerce Microservices Development</strong>: Developed microservices using <b>Kotlin/Java,
                        Vert.x, Kafka, Cassandra, Docker, Kubernetes</b> to manage delivery dates and optimize shipment
                        assignments. Co-authored algorithms for <b>Estimated Delivery Dates</b> and <b>Checkout
                            Assignment</b> to streamline processes.</p>

                    <br/>
                    <p><strong>In-memory Caching & Asynchronous Framework</strong>: Built a custom in-memory caching
                        system with <b>Kafka notifications</b> and <b>Caffeine</b>. Co-authored an asynchronous
                        framework in Kotlin, leveraging <b>Coroutines</b> and <b>Vert.x</b>, and developed DSLs to
                        simplify REST calls, database operations, and Kafka interactions.</p>
                    <br/>
                    <p><strong>Performance Optimization</strong>: Engineered real-time, data-intensive systems
                        using <b>Kotlin/Java, Kafka, Cassandra</b>, and optimized throughput with Coroutines,
                        improving response times by 97.5%.</p>
                </div>
            )
        },
        {
            title: "2018 - '21",
            content: (
                <div className={"text-teal-50"}>
                    <p className={"text-3xl font-extrabold"}>Accenture</p>
                    <br/>
                    <p><strong>Career Growth</strong>: I progressed from intern to <b>Associate Software
                        Engineer</b> and <b>Software Engineering Analyst</b> at Accenture, working extensively
                        with <b>Java Spring, Spring Boot</b>, and other backend technologies.</p>
                    <br/>
                    <p>I contributed to building scalable REST Microservices and SOAP monoliths, utilizing <b>MongoDB,
                        Oracle, Redis, RabbitMQ</b>, and integrating <b>Machine Learning</b> models. I also
                        implemented a custom <b>State Machine-based compression algorithm</b> to optimize large
                        payloads.</p>
                    <br/>
                    <p>As a developer and point of contact, I drove secure data transfers and developed
                        custom <b>HTTP Clients</b> for legacy systems. Throughout my tenure, I focused on
                        resolving production issues and enhancing system performance.</p>
                    <br/>
                </div>
            )
        },
        {
            title: "2018",
            content: (
                <div className={"text-teal-50"}>
                    <p>
                        Graduated from <b>Anna University</b> in my Bachelors in Computer Science & Engineering with an
                        offer from <b>Accenture</b> in hand.
                    </p>
                </div>
            )
        }
    ]

    return (
        <div>
            <div className="flex flex-col gap-2 flex-1 w-full h-full overflow-y-scroll">
                <SlideshowWithOverlay images={SLIDE_SHOW_IMAGES}>
                    <div
                        className="absolute inset-0 flex flex-col p-8 bg-opacity-50 bg-black overflow-y-scroll overflow-x-hidden">
                        <Timeline data={data}/>
                    </div>
                </SlideshowWithOverlay>
            </div>
        </div>
    )
}
