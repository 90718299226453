import React from "react";
import {useNavigate} from "react-router-dom";

export const InternalLinks = () => {

    const navigate = useNavigate();

    const navigateTo = (url: string) => {
        navigate(url);
    };

    const InternalButton = ({
                                navigateToUri,
                                buttonText
                            }: { navigateToUri: string, buttonText: string }) => {
        return (
            <button
                onClick={() => navigateTo(navigateToUri)}
                className="rounded-md border border-transparent py-2 px-4 flex items-center text-center text-sm transition-all text-white hover:bg-slate-900 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
                {buttonText}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                     className="w-4 h-4 ml-1.5">
                    <path fill-rule="evenodd"
                          d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                          clip-rule="evenodd"/>
                </svg>
            </button>
        )
    }

    return (
        <div className={"flex flex-row mt-10"}>
            <InternalButton navigateToUri={"/open-source-contributions"} buttonText={"Open Source"}/>
            <InternalButton navigateToUri={"/contact"} buttonText={"Say Hello 👋🏼"}/>
            <InternalButton navigateToUri={"/professional-timeline"} buttonText={"My Change Log"}/>
        </div>
    )
}
