import {BrowserRouter, RouteObject, useRoutes} from "react-router-dom";
import Home from "./pages/home";
import React from "react";
import {OpenSource} from "./pages/open-source";
import {SayHello} from "./pages/say-hello";
import {NotFound} from "./pages/not-found";
import {ProfessionalTimeline} from "./pages/professional-timeline";
import {OpenSourceV2} from "./pages/open-source-v2";

const AppRoutes: React.FC = () => {

    const routes: RouteObject[] = [
        {
            path: "/",
            element: <Home/>
        },
        {
            path: "/open-source-contributions",
            element: <OpenSourceV2/>
        },
        {
            path: "/contact",
            element: <SayHello/>
        },
        {
            path: "/professional-timeline",
            element: <ProfessionalTimeline/>
        },
        {
            path: "*",
            element: <NotFound/>
        }
    ]

    return useRoutes(routes);
};

function App() {


    return (
        <BrowserRouter>
            <AppRoutes/>
        </BrowserRouter>
    )
}

export default App;
