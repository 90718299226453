export const SLIDE_SHOW_IMAGES = [
    "assets/aseem_in_front_of_ocean.avif",
    "https://photography.aseemsavio.com/content/images/size/w1920/2024/08/ILCE-7M4413.jpg",
    "assets/aseem_in_jadayu.avif",
    "https://photography.aseemsavio.com/content/images/size/w2400/2024/08/DSC00218-1.jpg",
    "assets/aseem_on_a_boat.avif",
    "https://photography.aseemsavio.com/content/images/size/w2400/2024/08/DSC00651-1.jpg",
    "assets/aseem_in_front_of_valley.avif",
    "https://photography.aseemsavio.com/content/images/size/w2400/2024/08/DSC00491.jpg",
    "assets/aseem_on_a_bike.avif",
    "https://photography.aseemsavio.com/content/images/size/w2400/2024/11/DSC01639.jpg"
]
