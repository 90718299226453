import "../App.css";
import React, {useEffect} from "react";
import {BACKEND_URL} from "../lib/routing";
import {SlideshowWithOverlay} from "../components/custom/slideshow-with-overlay";
import {SLIDE_SHOW_IMAGES} from "../common/slide-show-images";
import {ExternalLinks} from "../components/custom/external-links";
import {InternalLinks} from "../components/custom/internal-links";

function Home() {
    useEffect(() => {
        document.title = "Aseem Savio"
        fetch(`${BACKEND_URL}/api/v1/site-visit`, {
            method: "POST", // Set the HTTP method to POST
            headers: {
                "Content-Type": "application/json", // Tell the server you're sending JSON
            },
        }).then((resp) => console.log(resp));

        fetch(`${BACKEND_URL}/api/v1/page-visit`, {
            method: "POST", // Set the HTTP method to POST
            headers: {
                "Content-Type": "application/json", // Tell the server you're sending JSON
            },
        }).then((resp) => console.log(resp));
    }, []);


    return (
        <div className="flex flex-col gap-2 flex-1 w-full h-full overflow-y-scroll">
            <SlideshowWithOverlay images={SLIDE_SHOW_IMAGES}>
                <div
                    className="absolute inset-0 flex flex-col items-center justify-center text-white p-8 bg-opacity-50 bg-black">
                    <div className="flex flex-col items-center justify-center">
                        <ExternalLinks/>
                        <InternalLinks/>
                        <div className={"pt-7"}>
                            <p className={"text-5xl md:text-7xl font-thin text-white"}>
                                Aseem Savio
                            </p>
                        </div>
                        <span className={"mt-7 mb-5 bg-slate-100 h-0.5 w-10"}/>
                        <p className={"text-xs md:text-sm font-thin text-white text-center"}>I'm a Software Engineer
                            👨🏻‍💻 by day, and a Hobbyist Wildlife Photographer 📸 at other times</p>
                    </div>
                </div>
            </SlideshowWithOverlay>
        </div>
    );

}

export default Home;
