import {SlideshowWithOverlay} from "../components/custom/slideshow-with-overlay";
import {SLIDE_SHOW_IMAGES} from "../common/slide-show-images";
import React, {useEffect} from "react";
import {openInNewTab} from "../lib/routing";

interface OpenSourceContributionProp {
    heading: string
    description: React.ReactNode
    link: string
    tags: string[]

}

const SeeLink = ({
                     navigateToUri,
                 }: { navigateToUri: string}) => {
    return (
        <button
            onClick={() => openInNewTab(navigateToUri)}
            className="mt-5 rounded-md border border-transparent py-2 px-4 flex items-center text-center text-sm transition-all text-white hover:bg-slate-900 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
            See Code
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 className="w-4 h-4 ml-1.5">
                <path fill-rule="evenodd"
                      d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                      clip-rule="evenodd"/>
            </svg>
        </button>
    )
}

const OPENSOURCE_CONTRIBUTIONS: OpenSourceContributionProp[] = [
    {
        heading: "Dynamo Kt - Kotlin SDK for Dynamo DB Interactions",
        description: <p>I created this <b>Kotlin</b> library to add support for immutable data classes in the <b>AWS Dynamo DB</b> SDKs. This is achieved through <b>code generation</b> at build time, thus reducing runtime-overhead significantly.
            The library also extends the V1 & V2 Java SDKs through the use of my <b>Domain Specific Language (DSLs)</b> and uniform Extension Functions for the Kotlin & Java SDKs.
        </p>,
        link: "https://github.com/aseemsavio/dynamo-kt",
        tags: [""]
    },
    {
        heading: "pyTenable - Python SDK for Tenable",
        description: <p>
            I am a <b>maintainer</b> and an active <b>contributor</b> to this Open Source project, with over <b>85K monthly users</b>, managed by Tenable. This Python SDK will help Tenable's customers to interface with Tenable's <b>REST</b> and <b>GraphQL</b> APIs.
        </p>,
        link: "https://github.com/tenable/pyTenable",
        tags: [""]
    },
    {
        heading: "Biblia Sacra Vulgata",
        description: <p>
            I created this project to serve The <b>Latin Vulgate</b> and it's English Translation as a <b>REST API</b>. This API does not employ a backing database, in lieu, uses an in-house built in-memory data store, which allows for constant time look-ups.
            I intend to include support for <b>Graal VM Native Images</b> which allows <b>Ahead of Time (AOT) Compilation</b> capabilities, which will result insignificantly reduced start-up times and memory foot-print
        </p>,
        link: "https://github.com/aseemsavio/Biblia-Sacra-Vulgata",
        tags: [""]
    },
    {
        heading: "Catholic CLI - CLI Theological Knowledge Base",
        description: <p>
            I built this <b>Command Line Interface</b> application as a <b>Theological</b> Knowledge base about the Catholic Church and its teachings for Theologian power users. This does <b>search</b> on various ancient Latin and English texts.
            This app is available for download at pypi.
        </p>,
        link: "https://github.com/aseemsavio/catholic-cli",
        tags: [""]
    }
]

const OpenSourceContribution = ({heading, description, link, tags}: OpenSourceContributionProp) => {
    return (
        <div
            className={"bg-black bg-opacity-20 md:w-2/3 w-3/4 p-5 mt-5 hover:bg-gray-900 hover:bg-opacity-20 transition-all duration-75 hover:shadow-xl hover:shadow-amber-100"}>
            <p className={"text-lg text-white font-extrabold"}>{heading}</p>
            <div className={"text-gray-300 text-sm mt-2 font-thin hover:font-medium transition-all duration-500"}>
                {description}
            </div>
            <SeeLink navigateToUri={link}/>
        </div>
    )
}

export const OpenSourceV2 = () => {

    useEffect(() => {
        document.title = "Aseem Savio | Open Source Projects"
    }, []);

    return (
        <div>
            <div className="flex flex-col gap-2 flex-1 w-full h-full overflow-y-scroll">
                <SlideshowWithOverlay images={SLIDE_SHOW_IMAGES}>
                    <div className="absolute inset-0 flex flex-col p-8 bg-opacity-50 bg-black overflow-y-scroll overflow-x-hidden">
                        <p className={"md:text-7xl text-4xl font-extrabold text-white"}>OPEN SOURCE</p>
                        <p className={"md:text-7xl text-2xl font-extrabold text-yellow-200"}>CONTRIBUTIONS</p>
                        <div className={"flex  flex-col items-center justify-center w-screen"}>
                            {OPENSOURCE_CONTRIBUTIONS.map( con => <OpenSourceContribution heading={con.heading} description={con.description} link={con.link} tags={con.tags} />)}
                        </div>
                        <br/>
                        <br/>
                    </div>
                </SlideshowWithOverlay>
            </div>
        </div>
    )
}
