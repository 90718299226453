export const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

//export const BACKEND_URL = "http://localhost:8080"
//export const BACKEND_URL = "http://192.168.1.35:8080"
//export const BACKEND_URL = "http://192.168.1.54:30005"
export const BACKEND_URL = "https://aseemsavio.com"

export const doPost = async (
    endpoint: string,
    body: object,
): Promise<Response> => {

    const jsonBody = JSON.stringify(body)
    const response = await fetch(`${BACKEND_URL}${endpoint}`, {
        method: 'POST',
        body: jsonBody,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })

    if (response.ok) {
        return response
    } else {
        throw Error(`Error occurred while invoking ${endpoint}. Error: ${response.status} ${await response.text()}`)
    }
}
