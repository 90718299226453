import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ExternalLinks } from "./external-links";
import { InternalLinks } from "./internal-links";

interface SlideshowProps {
    images: string[];
    duration?: number; // Duration of each image in seconds
    children: React.ReactNode
}

export const SlideshowWithOverlay: React.FC<SlideshowProps> = ({ images, duration = 5, children }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prev) => (prev + 1) % images.length); // Loop back to the start
        }, duration * 1000);

        return () => clearInterval(interval);
    }, [images.length, duration]);

    return (
        <div className="relative min-h-[100vh] w-full overflow-hidden">
            {/* Slideshow */}
            <div className="relative min-h-[100vh] w-full overflow-hidden">
                <AnimatePresence>
                    <motion.div
                        key={currentIndex} // Ensure a unique key for each image
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ backgroundImage: `url(${images[currentIndex]})` }}
                        initial={{ opacity: 0, scale: 1.2 }} // Start with slight zoom and fade-in
                        animate={{ opacity: 1, scale: 1.4 }} // Animate to full opacity and zoom
                        exit={{ opacity: 0, scale: 1.6 }} // Fade-out and zoom further
                        transition={{ duration: duration, ease: "linear" }} // Smooth transitions
                    />
                </AnimatePresence>

                {/* Dark overlay */}
                <div className="absolute inset-0 bg-black opacity-40" />
            </div>

            {/* Overlay Component */}
            {children}
        </div>
    );
};
